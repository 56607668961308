import dynamic from "next/dynamic";

interface SVGIconProps extends React.SVGProps<SVGSVGElement>{}
type SVGIcon = (props: SVGIconProps) => JSX.Element;

export const AuthSignIn = dynamic(() => import("./svg/auth-sign-in.svg")) as SVGIcon;
export const AppleShare = dynamic(() => import("./svg/apple-share.svg")) as SVGIcon;
export const ApplePlus = dynamic(() => import("./svg/apple-plus.svg")) as SVGIcon;
export const AuthSignUp = dynamic(() => import("./svg/auth-sign-up.svg")) as SVGIcon;
export const Securing = dynamic(() => import("./svg/securing.svg")) as SVGIcon;
export const ArrowBottom = dynamic(() => import("./svg/arrow-bottom.svg")) as SVGIcon;
export const ArrowCaretRight = dynamic(() => import("./svg/arrow-caret-right.svg")) as SVGIcon;
export const ArrowDown = dynamic(() => import("./svg/arrow-down.svg")) as SVGIcon;
export const ArrowUp = dynamic(() => import("./svg/arrow-up.svg")) as SVGIcon;
export const ArrowRight = dynamic(() => import("./svg/arrow-right.svg")) as SVGIcon;
export const ArrowShortLeft = dynamic(() => import("./svg/arrow-short-left.svg")) as SVGIcon;
export const ArrowShortRight = dynamic(() => import("./svg/arrow-short-right.svg")) as SVGIcon;
export const Ask = dynamic(() => import("./svg/ask.svg")) as SVGIcon;
export const Blurry = dynamic(() => import("./svg/blurry.svg")) as SVGIcon;
export const Bus = dynamic(() => import("./svg/bus.svg")) as SVGIcon;
export const Bell = dynamic(() => import("./svg/bell.svg")) as SVGIcon;
export const Bell2 = dynamic(() => import("./svg/bell2.svg")) as SVGIcon;
export const Camera = dynamic(() => import("./svg/camera.svg")) as SVGIcon;
export const CaretDown = dynamic(() => import("./svg/caret-down.svg")) as SVGIcon;
export const Cart = dynamic(() => import("./svg/cart.svg")) as SVGIcon;
export const CheckWhite = dynamic(() => import("./svg/check-white.svg")) as SVGIcon;
export const Check = dynamic(() => import("./svg/check.svg")) as SVGIcon;
export const Close = dynamic(() => import("./svg/close.svg")) as SVGIcon;
export const Close2 = dynamic(() => import("./svg/close2.svg")) as SVGIcon;
export const CheckBold = dynamic(() => import("./svg/check-bold.svg")) as SVGIcon;
export const CopyLink = dynamic(() => import("./svg/copy-link.svg")) as SVGIcon;
export const Copy = dynamic(() => import("./svg/copy.svg")) as SVGIcon;
export const Currency = dynamic(() => import("./svg/currency.svg")) as SVGIcon;
export const Dashboard = dynamic(() => import("./svg/dashboard.svg")) as SVGIcon;
export const Duplicate = dynamic(() => import("./svg/duplicate.svg")) as SVGIcon;
export const Cubes = dynamic(() => import("./svg/cubes.svg")) as SVGIcon;
export const Filter = dynamic(() => import("./svg/filter.svg")) as SVGIcon;
export const Heart = dynamic(() => import("./svg/heart.svg")) as SVGIcon;
export const HeartFill = dynamic(() => import("./svg/heart-fill.svg")) as SVGIcon;
export const HeartBlack = dynamic(() => import("./svg/heart.svg")) as SVGIcon;
export const Home = dynamic(() => import("./svg/home.svg")) as SVGIcon;
export const Hamburger = dynamic(() => import("./svg/hamburger.svg")) as SVGIcon;
export const Info = dynamic(() => import("./svg/info.svg")) as SVGIcon;
export const Image = dynamic(() => import("./svg/image.svg")) as SVGIcon;
export const ImageMedium = dynamic(() => import("./svg/image_medium.svg")) as SVGIcon;
export const Guard = dynamic(() => import("./svg/guard.svg")) as SVGIcon;
export const Search = dynamic(() => import("./svg/search.svg")) as SVGIcon;
export const MobileLogin = dynamic(() => import("./svg/mobile-login.svg")) as SVGIcon;
export const PlaceholderImage = dynamic(() => import("./svg/placeholder-image.svg")) as SVGIcon;
export const Unwatch = dynamic(() => import("./svg/unwatch.svg")) as SVGIcon;
export const User = dynamic(() => import("./svg/user.svg")) as SVGIcon;
export const Visible = dynamic(() => import("./svg/visible.svg")) as SVGIcon;
export const Watch = dynamic(() => import("./svg/watch.svg")) as SVGIcon;
export const LogoShop = dynamic(() => import("./svg/logo-shop.svg")) as SVGIcon;
export const Logout = dynamic(() => import("./svg/logout.svg")) as SVGIcon;
export const ListUi = dynamic(() => import("./svg/list-ui.svg")) as SVGIcon;
export const LabelCartApplePay = dynamic(() => import("./svg/label-cart-apple-pay.svg")) as SVGIcon;
export const LabelCartMaster = dynamic(() => import("./svg/label-cart-master.svg")) as SVGIcon;
export const LabelCartVisa = dynamic(() => import("./svg/label-cart-visa.svg")) as SVGIcon;
export const Minus = dynamic(() => import("./svg/minus.svg")) as SVGIcon;
export const Menu = dynamic(() => import("./svg/menu.svg")) as SVGIcon;
export const MenuVertical = dynamic(() => import("./svg/menu-vertical.svg")) as SVGIcon;
export const Plus = dynamic(() => import("./svg/plus.svg")) as SVGIcon;
export const ProductTypeSingle = dynamic(() => import("./svg/product-type-single.svg")) as SVGIcon;
export const ProductTypeVariations = dynamic(() => import("./svg/product-type-variations.svg")) as SVGIcon;
export const Products = dynamic(() => import("./svg/products.svg")) as SVGIcon;
export const Play = dynamic(() => import("./svg/play.svg")) as SVGIcon;
export const AddPlus = dynamic(() => import("./svg/add-plus.svg")) as SVGIcon;
export const Refresh = dynamic(() => import("./svg/refresh.svg")) as SVGIcon;
export const Reviews = dynamic(() => import("./svg/reviews.svg")) as SVGIcon;
export const Review = dynamic(() => import("./svg/review.svg")) as SVGIcon;
export const Map = dynamic(() => import("./svg/map.svg")) as SVGIcon;
export const CreditCard = dynamic(() => import("./svg/credit-card.svg")) as SVGIcon;
export const Edit = dynamic(() => import("./svg/edit.svg")) as SVGIcon;
export const Share = dynamic(() => import("./svg/share.svg")) as SVGIcon;
export const Star = dynamic(() => import("./svg/star.svg")) as SVGIcon;
export const StarFill = dynamic(() => import("./svg/star-fill.svg")) as SVGIcon;
export const StarNoFill = dynamic(() => import("./svg/star-no-fill.svg")) as SVGIcon;
export const StarFilled = dynamic(() => import("./svg/star-filled.svg")) as SVGIcon;
export const StatusInprogress = dynamic(() => import("./svg/status-inprogress.svg")) as SVGIcon;
export const StatusReady = dynamic(() => import("./svg/status-ready.svg")) as SVGIcon;
export const StatusCheck = dynamic(() => import("./svg/status-check.svg")) as SVGIcon;
export const StatusWaiting = dynamic(() => import("./svg/status-waiting.svg")) as SVGIcon;
export const StatusReadyReceived = dynamic(() => import("./svg/status-ready-received.svg")) as SVGIcon;
export const StatusCancelled = dynamic(() => import("./svg/status_cancelled.svg")) as SVGIcon;
export const SaleImage = dynamic(() => import("./svg/sale-image.svg")) as SVGIcon;
export const SocialNetwork = dynamic(() => import("./svg/social-network.svg")) as SVGIcon;
export const SortASC = dynamic(() => import("./svg/sort-asc.svg")) as SVGIcon;
export const SortDESC = dynamic(() => import("./svg/sort-desc.svg")) as SVGIcon;
export const SocialFacebook = dynamic(() => import("./svg/social/facebook.svg")) as SVGIcon;
export const SocialInstagram = dynamic(() => import("./svg/social/instagram.svg")) as SVGIcon;
export const SocialTwitter = dynamic(() => import("./svg/social/twitter.svg")) as SVGIcon;
export const Apple = dynamic(() => import("./svg/social/apple.svg")) as SVGIcon;
export const Playmarket = dynamic(() => import("./svg/social/playmarket.svg")) as SVGIcon;
export const Upload = dynamic(() => import("./svg/upload.svg")) as SVGIcon;
export const Comment = dynamic(() => import("./svg/comment.svg")) as SVGIcon;
export const Trash = dynamic(() => import("./svg/trash.svg")) as SVGIcon;
export const Exit = dynamic(() => import("./svg/exit.svg")) as SVGIcon;
export const Help = dynamic(() => import("./svg/help.svg")) as SVGIcon;
export const Location = dynamic(() => import("./svg/location.svg")) as SVGIcon;
export const Settings = dynamic(() => import("./svg/settings.svg")) as SVGIcon;
export const Settings24 = dynamic(() => import("./svg/settings_24.svg")) as SVGIcon;
export const Terms = dynamic(() => import("./svg/terms.svg")) as SVGIcon;
export const Notifications = dynamic(() => import("./svg/notifications.svg")) as SVGIcon;
export const NotificationOutline = dynamic(() => import("./svg/notification-outline.svg")) as SVGIcon;
export const NotificationsGift = dynamic(() => import("./svg/notifications-gift.svg")) as SVGIcon;
export const NotificationsCar = dynamic(() => import("./svg/notifications-car.svg")) as SVGIcon;
export const NotificationsCart = dynamic(() => import("./svg/notifications-cart.svg")) as SVGIcon;
export const NotificationsComment = dynamic(() => import("./svg/notifications-comment.svg")) as SVGIcon;
export const World = dynamic(() => import("./svg/world.svg")) as SVGIcon;
export const AttachFile = dynamic(() => import("./svg/attach-file.svg")) as SVGIcon;
export const Sent = dynamic(() => import("./svg/sent.svg")) as SVGIcon;
export const Picture = dynamic(() => import("./svg/picture.svg")) as SVGIcon;
export const Send = dynamic(() => import("./svg/send.svg")) as SVGIcon;
export const Feedback = dynamic(() => import("./svg/feedback.svg")) as SVGIcon;
export const Warranty = dynamic(() => import("./svg/warranty.svg")) as SVGIcon;
export const Attention = dynamic(() => import("./svg/attention.svg")) as SVGIcon;
export const Question = dynamic(() => import("./svg/question.svg")) as SVGIcon;
export const SettingsActive = dynamic(() => import("./svg/settings-active.svg")) as SVGIcon;
export const BluePoint = dynamic(() => import("./svg/blue-point.svg")) as SVGIcon;
export const SuccessPurchase = dynamic(() => import("./svg/success-purchase.svg")) as SVGIcon;
export const PaymentCanceled = dynamic(() => import("./svg/payment-canceled.svg")) as SVGIcon;
export const PaymentInprogress = dynamic(() => import("./svg/payment-inprogress.svg")) as SVGIcon;
export const Receipt = dynamic(() => import("./svg/receipt.svg")) as SVGIcon;
export const Orders = dynamic(() => import("./svg/orders.svg")) as SVGIcon;
export const Warning = dynamic(() => import("./svg/warning.svg")) as SVGIcon;
export const WarningOutlined = dynamic(() => import("./svg/warning_outlined.svg")) as SVGIcon;
export const Like = dynamic(() => import("./svg/like.svg")) as SVGIcon;
export const FacebookLarge = dynamic(() => import("./svg/social/facebook-large.svg")) as SVGIcon;
export const AppleLarge = dynamic(() => import("./svg/social/apple-large.svg")) as SVGIcon;
export const GoogleLarge = dynamic(() => import("./svg/social/google-large.svg")) as SVGIcon;
export const AuthEmailSent = dynamic(() => import("./svg/auth-email-sent.svg")) as SVGIcon;
export const MinusSimple = dynamic(() => import("./svg/minus-simple.svg")) as SVGIcon;
export const Shop = dynamic(() => import("./svg/shop.svg")) as SVGIcon;
export const Calc = dynamic(() => import("./svg/calc.svg")) as SVGIcon;
export const Import = dynamic(() => import("./svg/import.svg")) as SVGIcon;
export const Success = dynamic(() => import("./svg/success.svg")) as SVGIcon;
export const Viber = dynamic(() => import("./svg/viber.svg")) as SVGIcon;
export const Telegram = dynamic(() => import("./svg/telegram.svg")) as SVGIcon;
export const Skype = dynamic(() => import("./svg/skype.svg")) as SVGIcon;
export const Pdf = dynamic(() => import("./svg/pdf.svg")) as SVGIcon;
export const Jpg = dynamic(() => import("./svg/jpg.svg")) as SVGIcon;
export const Png = dynamic(() => import("./svg/png.svg")) as SVGIcon;
export const Txt = dynamic(() => import("./svg/txt.svg")) as SVGIcon;
export const Doc = dynamic(() => import("./svg/doc.svg")) as SVGIcon;
export const UnknownFile = dynamic(() => import("./svg/unknown_file.svg")) as SVGIcon;
export const Calendar = dynamic(() => import("./svg/calendar.svg")) as SVGIcon;
export const AuthTwoFactor = dynamic(() => import("./svg/two-factor-auth.svg")) as SVGIcon;
export const Download = dynamic(() => import("./svg/download.svg")) as SVGIcon;
export const OffClose = dynamic(() => import("./svg/off_close.svg")) as SVGIcon;
export const Support = dynamic(() => import("./svg/support.svg")) as SVGIcon;
export const Shipping = dynamic(() => import("./svg/shipping.svg")) as SVGIcon;
export const ArrowLongDown = dynamic(() => import("./svg/arrow-long-down.svg")) as SVGIcon;
export const Restore = dynamic(() => import("./svg/restore.svg")) as SVGIcon;
export const NovaPoshta = dynamic(() => import("./svg/nova_poshta.svg")) as SVGIcon;
export const UkrPoshta = dynamic(() => import("./svg/ukrpost.svg")) as SVGIcon;
export const Favorite = dynamic(() => import("./svg/favorite.svg")) as SVGIcon;
